import {FETCH_FILM, FETCH_FILMS} from  "../constants/film";


const initialState = {
  film:{
  },
  films:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_FILM:
      return Object.assign({}, state, {
         film: action.payload
       });
    case FETCH_FILMS:
      return Object.assign({}, state, {
          films: action.payload
      });
  }
  return state;
}

export default reducer;
