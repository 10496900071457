import React, { useEffect,  useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import Pagination from '@mui/material/Pagination';
import CardActions from '@mui/material/CardActions';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers, makeActive, makeInActive } from '../../actions/customer';


const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    inactive:{padding: 5, bordeWidth:1, borderStyle:'dotted', marginTop:5, borderRadius:0},
    active:{padding: 5, bordeWidth:1, borderColor:"#000000", borderStyle:'dotted', marginTop:5, borderRadius:0},
}

export default function Main(){

    const dispatch = useDispatch();
    const history = useHistory();

    const customerData  = useSelector(state => state.customers.customers);
    const [customers, setCustomers] = useState(customerData ? customerData : []); 
    const [active, setActive] = useState(customers ? customers.filter((item) => item.active == 1 ) : [] );
    const [inActive, setInActive] = useState(customers ? customers.filter((item) => item.active != 1 ) : [] );

    const [source, setSource] = useState([]);
    const [destination, setDestination] = useState([]);
    const [destIndex, setDestIndex] = useState(0);

    const [itemsPerPage, setItemsPerPage] = useState(15);

    const [activePage, setActivePage] = React.useState(1);
    const [activeStartIndex, setActiveStartIndex] = useState(0);
    const [activeEndIndex, setActiveEndIndex] =  useState(itemsPerPage - 1);

    const [inActivePage, setInActivePage] = React.useState(1);
    const [inActiveStartIndex, setInActiveStartIndex] = useState(0);
    const [inActiveEndIndex, setInActiveEndIndex] =  useState(itemsPerPage - 1);


    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
        setSource(e.target.parentNode.id);
    };
     
    const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
    setDestination(e.target.parentNode.id);
    setDestIndex(position)
    };

    const handleActivePageChange  = (event, value) => {
        setActivePage(value);
        setActiveStartIndex(itemsPerPage*(value -1))
        setActiveEndIndex(value*itemsPerPage - 1);
    }

    const handleInActivePageChange  = (event, value) => {
        setInActivePage(value);
        setInActiveStartIndex(itemsPerPage*(value -1))
        setInActiveEndIndex(value*itemsPerPage - 1);
    }

    const drop = (e, list, item) => {
        if(source != destination){
            if(source == "active"){
                item.active = 0;
                console.log(item)
                setInActive([item, ...inActive]);
                setActive(active.filter((it) => it != item));
                dispatch(makeInActive({id: item.customer_id}));

            }else{
                item.active = 1;
                setActive([item, ...active]);
                setInActive(inActive.filter((it) => it != item));
                dispatch(makeActive({id: item.customer_id}));
            }
        }
      };
    


    useEffect(() => {
        dispatch(getCustomers())
    }, [])

    useEffect(() => {
        setCustomers(customerData ? customerData : []);
    }, [customerData])

    useEffect(() => {
        setActive(customers ? customers.filter((item) => item.active == 1 ) : []);
        setInActive(customers ? customers.filter((item) => item.active != 1 ) : []);
    }, [customers])

    return(
      <div style={{marginTop:10}} className={classes.root}>
      <Grid justifyContent="center" alignItems="center" container direction="row">
        <Grid item xs={12} md={8} style={{marginTop:0, padding:10}}>
            <Card style={{backgroundColor: "#f5f5f5", borderRadius:20}} color="primary" elevation={20}>
            <CardContent>

            <Grid container direction="row" spacing={1}>
                <Grid item>
                <Fab onClick={() => history.goBack()} style={{color:"#000000"}} color="inherit" aria-label="add">
                <ChevronLeftIcon fontSize="large" />
                </Fab>
               
                </Grid>
                <Grid item>
                <Typography variant="h4"><b>Customers </b></Typography>
                <Typography color="textSecondary" variant="body1">There are {customers.length} customers (users) in the database. You can drag each customer between both active and inactive categories</Typography>
                </Grid>
            </Grid>
           

            <div style={{marginTop:20}}>
            <Grid container direction="row" spacing={1}>
            <Grid item md={6}>
            <Typography variant="h6"> Active ({active.length}) </Typography>
            <div id="active">
            {active.length > 0 ? active.map((item, index) => (
                index >= activeStartIndex && index <= activeEndIndex &&
                     <ListItem 
                draggable 
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={(e) => drop(e, 'active', item)}
                style = {styles.active} >
                     <Item data={item} />
                </ListItem> 
            )): <Skeleton />}
            <div style={{marginTop:10}}>
            <Pagination page={activePage} 
            onChange={handleActivePageChange}
            count={Math.ceil(active.length/itemsPerPage)} size="large" />
            </div>
          
            </div>
            </Grid>
            <Grid item md={6}>
            <Typography variant="h6"> Non-Active ({inActive.length}) </Typography>
            <div id="inactive">
            {inActive.length > 0 ? inActive.map((item, index) => (
                index >= inActiveStartIndex && index <= inActiveEndIndex && 
                <ListItem 
                disabled
                draggable  
                onDragStart={(e) => dragStart(e, index)}
                onDragEnter={(e) => dragEnter(e, index)}
                onDragEnd={(e) => drop(e, 'inactive', item)} 
                style={styles.inactive}  >
                     <Item data={item} />
                </ListItem>
            )): <Skeleton />}
            <div style={{marginTop:10}}>
            <Pagination page={inActivePage} 
            onChange={handleInActivePageChange}
            count={Math.ceil(inActive.length/itemsPerPage)} size="large" />
            </div>
            </div>
           
            </Grid>
            </Grid>
            </div>


            </CardContent>
            </Card>


            
        </Grid>
      </Grid>
           
        </div>
    )
}

function Item(props){
    return(
        <Grid style={{width:"100%"}} container direction="row" justify="space-between">
            <Grid item md={10}>
            <Typography variant="h6"> {props.data.first_name + " " + props.data.last_name} </Typography>
            </Grid>
            <Grid item md={2}>
            <Chip label={props.data.active == 1 ? "active" : "inactive"}  variant="outlined" />
            </Grid>
        </Grid>
    )
}