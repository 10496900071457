import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_PAYMENT, FETCH_PAYMENTS} from '../constants/payment';


export function getPayments(body){
  const url = '/payment/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.payments != null){
          dispatch(fetchPayments(res.response.payments));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}





export function fetchPayments(payload){
  return { type: FETCH_PAYMENTS, payload }
}

export function fetchPayment(payload){
  return { type: FETCH_PAYMENT, payload }
}




