import {FETCH_CUSTOMER, FETCH_CUSTOMERS} from  "../constants/customer";


const initialState = {
  customer:{
  },
  customers:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_CUSTOMER:
      return Object.assign({}, state, {
         customer: action.payload
       });
    case FETCH_CUSTOMERS:
      return Object.assign({}, state, {
          customers: action.payload
      });
  }
  return state;
}

export default reducer;
