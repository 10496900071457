import { FETCH_CATEGORIES, FETCH_CATEGORY } from "../constants/category";


const initialState = {
  category:{
  },
  categories:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_CATEGORIES:
      return Object.assign({}, state, {
         categories: action.payload
       });
    case FETCH_CATEGORY:
      return Object.assign({}, state, {
          category: action.payload
      });
  }
  return state;
}

export default reducer;
