import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Fab from '@mui/material/Fab';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


const classes = {
    root:{
        marginTop:10
    }
}

export default function Main(){
    const history = useHistory();


    return(
      <div className={classes.root}>
      <Grid justifyContent="center" alignContent="center" container direction="row">
        <Grid item xs={12} md={8} style={{marginTop:0, padding:10}}>
            <Card style={{backgroundColor: "#f5f5f5", borderRadius:20}} elevation={20} color="primary">
            <CardContent>

            <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                <Fab onClick={() => history.goBack()} style={{color:"#000000"}} color="inherit" aria-label="add">
                <ChevronLeftIcon fontSize="large" />
                </Fab>
               
                </Grid>
                <Grid item>
                <Typography  variant="h4"><b>About </b></Typography>

                </Grid>
            </Grid>
          
          
            
            <div style={{marginTop:10}}>
            <Typography variant="h6"> <b> Frameworks and Languages </b></Typography>
            <Typography color="textSecondary" variant="body1">
            The front end was developed with JavaScript using React.js and the backend was built with PHP using CakePHP frameworks. 
            Material UI Library was used to implement most of the front-end components although in the first task requiring a drag and drop feature, it had to be built for the task. 
            Redux was used for state management. React Router for navigation. Google Charts for data displays. 
            For more details see package.json file in the app folder
            </Typography>
            </div>


            <div style={{marginTop:10}}>
            <Typography variant="h6"> <b> Frontend setup </b></Typography>
            <Typography color="textSecondary" variant="body1">
            <ul>
            <li> Download or clone this repository </li>	
            <li> Open terminal in the directory containing the app </li>
            <li> Type `npm install` to install all project dependencies</li>
            <li> Browse to constants folder and open settings.js </li>
            <li> Change between live or test mode by changing the "mode" property to "live" or "test" </li>
            <li> Changing to "live" means the api requests will be served by https://snt-api.thelastenvoy.com </li>
            <li> Changing to "test" means the api requests will be served locally on your choice local IP, the default used on the machine used to develop this project is http://127.0.0.20. 
                If you wish to run locally you need to edit the "test" property accordingly.</li>
            <li> Now run `npm start` to run the project locally.</li>
            <li> Alternatively you can view this project at https://snt-app.thelastenvoy.com, the server is a bit sluggish due to limited resources but be patient. </li>
            </ul>
            


            </Typography>
            </div>


            <div style={{marginTop:10}}>
            <Typography variant="h6"> <b>Backend setup </b></Typography>
            <Typography color="textSecondary" variant="body1">
                <ul>
                 <li>Download or clone this repository to a choice directory</li>
                 <li>Install WAMP or LAMP server depending on your OS</li>
                 <li>Create a virtual host for the api in WAMP or LAMP</li>
                 <li>Edit the `config\app_local.php` with the appropriate database name and mysql credentials to access the database. </li>
                 <li>The api can be accessed on this server as well https://snt-api.thelastenvoy.com</li>
                 <li>	Please find general installation details related to CAKEPHP below.</li>

                </ul>
            </Typography>
            </div>
           

            
           
            
        </CardContent>
        </Card>
      
    
        </Grid>
      </Grid>
           
        </div>
    )
}