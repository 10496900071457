import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Link, BrowserRouter} from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import logo from './logo.svg';
import history from './history';
import './App.css';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Backdrop, Button, CircularProgress} from '@mui/material';
import {globalStyles} from './styles/global.js';
//Account

import Start from './screens/start/Main';
import About from './screens/start/About';
import Customers from './screens/customers/Main';
import Dashboard from './screens/dashboard/Main';
import Notify from './components/page/Notify';

import {THEME} from './styles/theme.js'

import {getPayments} from './actions/payment';
import {getRentals} from './actions/rental';
import {getInventories} from './actions/inventory';



function App() {
  const loading = useSelector(state => state.app.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPayments());
    dispatch(getRentals());
    dispatch(getInventories());
  }, []);
  
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>    
       <Router history={history}> 
           <Notify />
           <Backdrop style={{zIndex:100}} open={loading}>
             <CircularProgress color="inherit" />
           </Backdrop>
           <Switch>
             <Route exact path="/" component={Start} />
             <Route exact path="/customers" component={Customers} />
             <Route exact path="/dashboard" component={Dashboard} />
             <Route exact path="/about" component={About} />
           </Switch>
       
         </Router>
       </ThemeProvider>
       </StyledEngineProvider>
  );
}

export default App;
