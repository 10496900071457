import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_FILM, FETCH_FILMS} from '../constants/film';


export function getFilms(body){
  const url = '/film/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.films != null){
          dispatch(fetchFilms(res.response.films));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}




export function fetchFilms(payload){
  return { type: FETCH_FILMS, payload }
}



export function fetchFilm(payload){
  return { type: FETCH_FILM, payload }
}




