import React from 'react';
import {useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import {task1} from '../../images/task1.jpeg'
const classes = {
    root:{
        marginTop:10
    }
}

const styles = {
    media: {
      height: '100%',
      paddingTop: '75%', // 16:9,
      marginTop:'30'
    }
};
export default function Main(){
    const history = useHistory();


    return(
      <div className={classes.root}>
      <Grid justifyContent="center" alignContent="center" container direction="row">
        <Grid item xs={12} md={8} style={{marginTop:0, padding:10}}>
            <Card style={{backgroundColor: "#f5f5f5", borderRadius:20}} elevation={20} color="primary">
            <CardContent>
            <Typography  variant="h4"><b>SNT Advanced Software Developer Assessment</b></Typography>

            
            <div style={{marginTop:20}}>
            <Grid container direction="row" spacing={1}>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined"
                 onClick={() => history.push("/about")}><Typography style={{color:"#000000"}}>Summary</Typography></Button>
                </Grid>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined" 
                onClick={() => window.open('https://gitlab.com/mojeed.oyedeji/snt-app')}><Typography style={{color:"#000000"}}>FE Gitlab</Typography></Button>
                </Grid>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined" 
                onClick={() => window.open('https://gitlab.com/mojeed.oyedeji/snt-api')}><Typography style={{color:"#000000"}}>BE Gitlab</Typography></Button>
                </Grid>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined"
                 onClick={() => window.open("https://youtu.be/f2I6fUhCZaI")}><Typography style={{color:"#000000"}}>YouTube</Typography></Button>
                </Grid>
            </Grid>
            </div>
            

            <div style={{marginTop:40}}>
          

            <div style={{marginBottom:10}}>
            <Typography  variant="h6"><b>Task 1</b></Typography>
            <Typography color="textSecondary" variant="body1">
                The first task requires listing active and non active users (customers) in a database.
                There should be a drag and drop feature to move a user between active and non-active categories.
                The active attribute of the user should update in the database with respect to changes made in the UI.
            </Typography>


            <div style={{marginTop:20}}>
            <Grid container direction="row" spacing={1}>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined" onClick={() => history.push("/customers")}>
                    <Typography style={{color:"#000000"}}>View</Typography></Button>
                </Grid>
               
            </Grid>
            </div>
            

          
            <Card style={{marginTop:10}}>
            <img width="100%"  src={require('../../images/task1.jpeg')} />
            </Card>
           
            

            </div>

           
            <Divider style={{ width: '100%', borderColor:'#000000'}} />
           
           
            <div style={{marginTop:10}}>
            <Typography variant="h6"><b>Task 2 </b></Typography>
            <Typography color="textSecondary" variant="body1">
            The second task requires building a dashboard for the film class. Some suggestions were to show on the dashboard how much clients paid for films, quantity of films by languages, quantity of films in every category and rating the top 10 users 
            </Typography>
            <div style={{marginTop:20}}>
            <Grid container direction="row" spacing={1}>
                <Grid item>
                <Button style={{borderColor:"#000000"}} variant ="outlined" onClick={() => history.push("/dashboard")}>
                    <Typography style={{color:"#000000"}}>View</Typography></Button>
                </Grid>
               
            </Grid>
            </div>

            <Card style={{marginTop:10}}>
            <img width="100%"  src={require('../../images/task2.jpeg')} />
            </Card>
            
            </div>

      
            </div>
            
        </CardContent>
        </Card>
      
    
        </Grid>
      </Grid>
           
        </div>
    )
}