import {FETCH_RENTAL, FETCH_RENTALS} from  "../constants/rental";


const initialState = {
  rental:{
  },
  rentals:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_RENTAL:
      return Object.assign({}, state, {
         rental: action.payload
       });
    case FETCH_RENTALS:
      return Object.assign({}, state, {
         rentals: action.payload
      });
  }
  return state;
}

export default reducer;
