import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_CUSTOMER, FETCH_CUSTOMERS} from '../constants/customer';


export function getCustomers(body){
  const url = '/customer/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.customers != null){
          dispatch(fetchCustomers(res.response.customers));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function makeActive(body){
  const url = '/customer/active';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      if(status == "ok"){
        if(res.response.customers != null){
          dispatch(fetchCustomers(res.response.customers));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}

export function makeInActive(body){
  const url = '/customer/inactive';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, body);
      if(status == "ok"){
        if(res.response.customers != null){
          dispatch(fetchCustomers(res.response.customers));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}


export function fetchCustomers(payload){
  return { type: FETCH_CUSTOMERS, payload }
}

export function fetchCustomer(payload){
  return { type: FETCH_CUSTOMER, payload }
}




