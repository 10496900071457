import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Fab from '@mui/material/Fab';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Pagination from '@mui/material/Pagination';
import { useDispatch, useSelector } from 'react-redux';

import { Chart } from "react-google-charts";

import MovieIcon from '@mui/icons-material/Movie';
import PaidIcon from '@mui/icons-material/Paid';
import InventoryIcon from '@mui/icons-material/Inventory';
import TheatersIcon from '@mui/icons-material/Theaters';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {getFilms} from '../../actions/films';
import {getCategory} from '../../actions/category';
import {getLanguage} from '../../actions/language';
import {getCustomers} from '../../actions/customer';
import {getRentals} from '../../actions/rental';
import {getPayments} from '../../actions/payment';
import {getInventories} from '../../actions/inventory';

const classes = {
    root:{
        marginTop:10
    }
}




  export const category_options = {
    title: "Films by category",
    pieHole: 0.4,
    is3D: true,
    backgroundColor: '#f5f5f5',
    fontName:"Urbanist",
    titleTextStyle: {
        fontSize: 25,
    }
  };

  export const language_options = {
    title: "Films by language",
    pieHole: 0.4,
    is3D: true,
    backgroundColor: '#f5f5f5',
    fontName:"Urbanist",
    titleTextStyle: {
        fontSize: 25,
    }
  };


export const styles = {
    listItem:{paddingLeft:5, marginTop:5, borderWidth:1, borderStyle:"dashed"},
    listHeader:{paddingLeft:5, marginTop:5, borderWidth:1, borderStyle:"dashed"}
}
 

export default function Main(){
    const dispatch = useDispatch();
    const history = useHistory();
    const filmData = useSelector(state => state.films.films);
    const [films, setFilms] = useState(filmData ? filmData : []);
    const categories = useSelector(state => state.categories.categories);
    const languages = useSelector(state => state.languages.languages);
    const customers  = useSelector(state => state.customers.customers); 
    const [customer, setCustomer] = useState(customers ? customers.sort(function(a, b){return b.rentals - a.rentals}) : []);
    const paymentData = useSelector(state => state.payments.payments);
    const [payments, setPayments] = useState(paymentData ? paymentData : []);
    const rentalData  = useSelector(state => state.rentals.rentals);
    const [rentals, setRentals] = useState(rentalData ? rentalData : []);
    const inventoryData = useSelector(state => state.inventories.inventories);
    const [inventories, setInventories] = useState(inventoryData ? inventoryData: []);

    const [category, setCategory] = useState(categories ? categories : []);
    const [language, setLanguage] = useState(languages ? languages : []);
    const [byCategory, setByCategory] = useState([])
    const [byLanguage, setByLanguage] = useState([])

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [page, setPage] = React.useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] =  useState(itemsPerPage - 1);
    
    useEffect(() => {
        dispatch(getCustomers());
        dispatch(getFilms());
        dispatch(getCategory());
        dispatch(getLanguage());
        dispatch(getRentals());
        dispatch(getPayments())
        dispatch(getInventories());
    }, [])

    useEffect(() => {
        setCategory(categories ? categories : []);
        categorySummary()
    }, [categories])

    useEffect(() => {
        setLanguage(languages ? languages : []);
        languageSummary()
    }, [languages])

    useEffect(() => {
        setPayments(paymentData ? paymentData : [])
    }, [paymentData])

    useEffect(() => {
        setRentals(rentalData ? rentalData : [])
    }, [rentalData])

    useEffect(() => {
        setInventories(inventoryData ? inventoryData : [])
    }, [inventoryData])

    useEffect(() => {
        setCustomer(customers ? customers : [])
    }, [customers])

    useEffect(() => {
        setFilms(filmData ? filmData : [])
        categorySummary()
        languageSummary()
    }, [filmData])

    function languageSummary(){
        var data = [["Language", "Count"]];
        if(language.length > 0){
            for( var k = 0; k < language.length; k++){
                var name = language[k].name;
                var value = films.filter((item) => item.language_id == language[k].language_id).length;
                data.push([name, value]);
            }
            setByLanguage(data)
        }
    }


    const handlePageChange  = (event, value) => {
        setPage(value);
        setStartIndex(itemsPerPage*(value -1))
        setEndIndex(value*itemsPerPage - 1);
    }

    function categorySummary(){
        var data = [["Category", "Count"]];
        if(category.length > 0){
            for( var k = 0; k < category.length; k++){
                var name = category[k].name;
                var value = films.filter((item) => item.category == category[k].category_id).length;
                data.push([name, value]);
            }
            setByCategory(data)
        }
    }

    function getCustomer(id){
        if(customer.length > 0){
        var cust = customer.filter((item) => item.customer_id == id)
        return cust[0].first_name + " " + cust[0].last_name;
        }
        
    }

    function getFilm(id){
        if(films.length > 0){
            var inventory = inventories.filter((item) => item.inventory_id == id);
            var film = films.filter(item => item.film_id == inventory[0].film_id);
            return film[0].title;
        }
        
    }

    function getPayment(id){
        if(payments){
            var payment = payments.filter((item) => item.rental_id == id);
            if(payment.length > 0){
                return payment[0].amount;
            }else{
                return 0
            }
            
        }else{
            return 0;
        }
        
    }

    function getEarnings(){
        if(payments){
            var sum = 0;
        for( var k = 0; k < rentals.length; k++){
            var payment = payments.filter((item) => item.rental_id == rentals[k].rental_id);
            sum = sum + payment[0].amount;
        }
        return sum
        }else{
            return 0;
        }
        
    }

    function getRental(id){
        var sum = 0;
        var inventory = inventories.filter((item) => item.rental_id == id);
        for( var k = 0; k < inventory.length; k++){
            var rentals = rentals.filter((item) => item.rental_id == inventory[k].rental_id);
            sum = sum + rentals.length;
        }
        return sum
    }

    return(
      <div style={{marginTop:10}} className={classes.root}>
      <Grid justifyContent="center" alignItems="center" container direction="row">
        <Grid item xs={12} md={8} sm={10} style={{marginTop:0, padding:10}}>
            <Card elevation={20} style={{backgroundColor: "#f5f5f5", borderRadius:20}}  color="primary">
            <CardContent>

            <Grid container direction="row" spacing={1}>
                <Grid item>
                <Fab onClick={() => history.goBack()} style={{color:"#000000"}} color="inherit" aria-label="add">
                <ChevronLeftIcon fontSize="large" />
                </Fab>
               
                </Grid>
                <Grid item>
                <Typography variant="h4"> <b>Dashboard</b></Typography>
                <Typography color="textSecondary" variant="body1">Film </Typography>
                </Grid>
            </Grid>
          

                <Grid container direction="row" style={{marginTop:20}} spacing={2} justifyContent="space-between">
                    <Grid item md={2}>
                    <Typography variant="body1" color="textSecondary" >Films</Typography>
                    {films.length > 0 ?  <Grid container direction="row" alignItems="flex-end">
                                <Grid item>
                                    <MovieIcon fontSize="inherit" style={{fontSize:50}}  />
                                </Grid>
                                <Grid item>
                                <Typography variant="h4">{films.length || 0}</Typography>
                                </Grid>
                                
                            </Grid> : <Skeleton />}
                           
                            
                           
                    </Grid>
                    <Grid item md={3}>
                            <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
                                
                                <Grid item>
                                <Typography variant="body1" color="textSecondary">Earnings</Typography>
                                {payments.length > 0 ?
                                  <Typography variant="h4">$ {payments.reduce((a,v) =>  a = a + Number(v.amount) , 0 ).toFixed(2)} </Typography>
                                  :
                                  <Skeleton />
                                }
                              
                                </Grid>
                                <Grid item>
                                
                                </Grid>
                            </Grid>
                            
                         
                    </Grid>
                    <Grid item md={2}>
                    <Typography variant="body1" color="textSecondary">Inventory</Typography>
                    {inventories.length > 0 ?
                    <Grid container direction="row" alignItems="flex-end"> 
                    <Grid item>
                    <InventoryIcon fontSize="inherit" style={{fontSize:50}}  />
                    </Grid>
                    <Grid item>
                    <Typography variant="h4">{inventories ? inventories.length : 0}</Typography>
                    </Grid>
                    </Grid>:
                    <Skeleton />
                    }
                    
                        
                            
                          
                    </Grid>
                    <Grid item md={2}>
                    <Typography variant="body1" color="textSecondary">Rentals</Typography>
                    {rentals.length > 0 ?
                        <Grid container direction="row" alignItems="flex-end" >
                        <Grid item>
                            <TheatersIcon fontSize="inherit" style={{fontSize:50}}  />
                            </Grid>
                            <Grid item>
                        <Typography variant="h4">{rentals.length || 0}</Typography>
                            </Grid>
                        </Grid>:
                     <Skeleton /> 
                    }
                            
                       
                           
                    </Grid>
                </Grid>

                <div style={{marginTop:20}}>
                <Grid container direction="row" spacing={1}>

                    <Grid item md={6}>
                    {byCategory.length > 0 ? 
                         <Chart
                         chartType="PieChart"
                         width="100%"
                         height="400px"
                         data={byCategory}
                         options={category_options}
                         />:
                         <Skeleton variant="circular" width={100} height={100} />
                    }
                   
                    </Grid>
                    <Grid item md={6}>
                    {byLanguage.length > 0 ?
                         <Chart
                         chartType="PieChart"
                         width="100%"
                         height="400px"
                         data={byLanguage}
                         options={language_options}
                         />:
                         <Skeleton variant="circular" width={100} height={100} />
                    }
                   
                    </Grid>
                </Grid>
                </div>

                <div style={{marginTop:10}}>
                <Grid container direction="row" spacing={1}>
                <Grid item md={12}>
                <Typography variant="h6" ><b>Rentals</b></Typography>

                <ListItem>
                <RentalHeader />
                </ListItem>

                {rentals.length > 0 ? rentals.map((item, index) => (
                     index >= startIndex && index <= endIndex && 
                    <ListItem divider>
                         <RentalItem index={index} data={item} 
                         customer={getCustomer(item.customer_id)} 
                         amount = {getPayment(item.rental_id)}
                         film = {getFilm(item.inventory_id)} />
                    </ListItem>

                   
                )): <Skeleton /> }

                <div style={{marginTop:10, marginBottom:10}}>
                <Pagination style={{width:"100%"}} page={page} 
                    onChange={handlePageChange}
                    count={Math.ceil(rentals.length/itemsPerPage)} size="large" />
                </div>
                     
                
                </Grid>
                </Grid>



                </div>
               

                <div style={{marginTop:10}}>
                <Grid container direction="row" spacing={1}>
                    <Grid item md={6}>
                  
                        <Typography variant="h6" ><b>Customers (top ten by rental frequency) </b></Typography>

                        <ListItem style={styles.listHeader}>
                            <CustomerHeader />
                        </ListItem>
                            {customers.length > 0 ? customers.map((item, index) => (
                                index < 10 &&
                                <ListItem style={styles.listItem}>
                                    <Customer data={item} />
                                </ListItem>
                            
                            )): <Skeleton />}
                       
                  
                    </Grid>
                    <Grid item md={6}>
                    
                        <Typography variant="h6" ><b>Films (top ten most rented) </b></Typography>

                        <ListItem style={styles.listHeader}>
                            <FilmHeader />
                        </ListItem>
                    {films.length >0 ? films.sort(function(a, b){return b.rental_rate - a.rental_rate}).map((item, index) => (
                         index < 10 &&
                         <ListItem style={styles.listItem}>
                               <Film data={item}  />
                         </ListItem>
                      
                    )): <Skeleton />}
                       
                  
                    </Grid>
                </Grid>
                </div>
               
               


            
            </CardContent>
            </Card>
        </Grid>
      </Grid>
           
        </div>
    )
}

function FilmHeader(props){
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item md = {10}>
                <Typography color="textSecondary" variant="body1">  FILM  </Typography>
            </Grid>
            <Grid item md={2}>
                <Typography color="textSecondary" align="end" variant="body1">  RATE  </Typography>
            </Grid>
        </Grid>
    )
}

function CustomerHeader(props){
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item md = {10}>
                <Typography color="textSecondary" variant="body1">  CUSTOMER  </Typography>
            </Grid>
            <Grid item md={2}>
                <Typography color="textSecondary" align="end" variant="body1">  RENTALS  </Typography>
            </Grid>
        </Grid>
    )
}

function Film(props){
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item>
                <Typography variant="body1">  {props.data.title}  </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1">  {props.data.rental_rate}  </Typography>
            </Grid>
        </Grid>
    )
}

function Customer(props){
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item md={10}>
                <Typography variant="body1"> {props.data.first_name} {props.data.last_name} </Typography>
            </Grid>
            <Grid item md={2}>
                <Typography align="end" variant="body1">{props.data.rentals} </Typography>
            </Grid>
        </Grid>
    )
}

function RentalHeader(props){
    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item md={1}>
                <Typography color="textSecondary" variant="body1">#</Typography>
            </Grid>
            <Grid item md={2}>
                <Typography color="textSecondary" variant="body1">Customer</Typography>
            </Grid>
            <Grid item md={3}>
                <Typography color="textSecondary" variant="body1">Film</Typography>
            </Grid>
            <Grid item md={1}>
                <Typography color="textSecondary" variant="body1">Amount</Typography>
            </Grid>
            <Grid item md={2}>
                <Typography color="textSecondary" variant="body1">Date</Typography>
            </Grid>
        </Grid>
    )
}

function RentalItem(props){
  


    return(
        <Grid container direction="row" justifyContent="space-between">
            <Grid item md={1}>
                <Typography color="textSecondary" variant="body1">{props.index+1}</Typography>
            </Grid>
            <Grid item md={2}>
                <Typography  variant="body1">{props.customer}</Typography>
            </Grid>
            <Grid item md={3}>
                <Typography  variant="body1">{props.film}</Typography>
            </Grid>
            <Grid item md={1}>
                <Typography variant="body1">$ {props.amount}</Typography>
            </Grid>
            <Grid item md={2}>
                <Typography  variant="body1">{props.data.rental_date.substr(0,10)}</Typography>
            </Grid>
        </Grid>
    )
}