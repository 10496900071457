import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_LANGUAGES, FETCH_LANGUAGE} from '../constants/language';



export function getLanguage(body){
  const url = '/language/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.languages != null){
          dispatch(fetchLanguages(res.response.languages));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}


export function fetchLanguages(payload){
  return { type: FETCH_LANGUAGES, payload }
}

export function fetchLanguage(payload){
  return { type: FETCH_LANGUAGE, payload }
}






