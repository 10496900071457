import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_RENTAL, FETCH_RENTALS} from '../constants/rental';


export function getRentals(body){
  const url = '/rental/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.rentals != null){
          dispatch(fetchRentals(res.response.rentals));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}





export function fetchRentals(payload){
  return { type: FETCH_RENTALS, payload }
}

export function fetchRental(payload){
  return { type: FETCH_RENTAL, payload }
}




