import {FETCH_PAYMENT, FETCH_PAYMENTS} from  "../constants/payment";


const initialState = {
  payment:{
  },
  payments:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_PAYMENT:
      return Object.assign({}, state, {
         payment: action.payload
       });
    case FETCH_PAYMENTS:
      return Object.assign({}, state, {
         payments: action.payload
      });
  }
  return state;
}

export default reducer;
