import { FETCH_LANGUAGES, FETCH_LANGUAGE } from "../constants/language";


const initialState = {
  language:{
  },
  languages:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_LANGUAGES:
      return Object.assign({}, state, {
         languages: action.payload
       });
    case FETCH_LANGUAGE:
      return Object.assign({}, state, {
          language: action.payload
      });
  }
  return state;
}

export default reducer;
