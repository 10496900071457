import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_INVENTORY, FETCH_INVENTORIES} from '../constants/inventory';


export function getInventories(body){
  const url = '/inventory/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.inventories != null){
          dispatch(fetchInventories(res.response.inventories));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}





export function fetchInventories(payload){
  return { type: FETCH_INVENTORIES, payload }
}

export function fetchInventory(payload){
  return { type: FETCH_INVENTORY, payload }
}




