import {FETCH_INVENTORY, FETCH_INVENTORIES} from  "../constants/inventory";


const initialState = {
  inventory:{
  },
  inventory:[]
};

function reducer(state = initialState, action) {
  switch (action.type){
    case FETCH_INVENTORY:
      return Object.assign({}, state, {
         inventory: action.payload
       });
    case FETCH_INVENTORIES:
      return Object.assign({}, state, {
          inventories: action.payload
      });
  }
  return state;
}

export default reducer;
