import {defaultHandler} from '../requests/global.js';
import {unexpectedError, wait, notify} from './index';
import {FETCH_CATEGORIES, FETCH_CATEGORY} from '../constants/category';



export function getCategory(body){
  const url = '/category/all';
  return async dispatch => {
    dispatch(wait(true))
      const [res, status] = await defaultHandler(url, {});
      if(status == "ok"){
        if(res.response.categories != null){
          dispatch(fetchCategories(res.response.categories));
        }else{
        }
      }else{
        dispatch(unexpectedError())
      }
      dispatch(wait(false))
    };
}


export function fetchCategories(payload){
  return { type: FETCH_CATEGORIES, payload }
}

export function fetchCategory(payload){
  return { type: FETCH_CATEGORY, payload }
}






